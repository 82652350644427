import MDRouter from "@/service/router";
export default {
  data() {
    return {
      src: '',
      video: null,
      ifShow: true,
      // 是否显示播放控制
      duration: null,
      //总时长
      currentTime: null,
      // 当前时长
      ifPlay: true,
      // 是否播放
      ifMuted: true,
      // 是否静音
      showPlayIcon: true,
      // 播放按钮
      img_url: '' //视频封面
    };
  },
  beforeMount() {
    this.src = this.$route.query.src;
    this.img_url = this.$route.query.img_url;
  },
  mounted() {
    const _this = this;
    _this.video = _this.$refs.video;
    _this.video.addEventListener('timeupdate', () => {
      _this.currentTime = Math.floor(_this.video.currentTime);
      _this.duration = Math.floor(_this.video.duration);
    });
    _this.video.addEventListener('play', () => {
      this.showPlayIcon = false;
    });
  },
  unmounted() {
    this.video.removeEventListener('timeupdate', () => {
      this.video = null;
    });
    this.video.removeEventListener('play', () => {
      this.showPlayIcon = true;
    });
  },
  methods: {
    play(type) {
      this.ifPlay = type;
      if (type) {
        this.video.play();
      } else {
        this.video.pause();
      }
    },
    muted(type) {
      this.ifMuted = type;
      this.video.muted = type;
    },
    onChange(num) {
      this.video.currentTime = num;
    },
    formatTime(time) {
      const cdTime = time;
      let minute = Math.floor(cdTime % (24 * 60 * 60) % (60 * 60) / 60);
      let sec = Math.floor(cdTime % (24 * 60 * 60) % (60 * 60) % 60);
      minute = minute > 9 ? minute : '0' + minute;
      sec = sec > 9 ? sec : '0' + sec;
      const timeText = `${minute}:${sec}`;
      return timeText;
    },
    goBack() {
      MDRouter.goBack();
    },
    // 视频播放
    videoPlay() {
      this.video.play();
    }
  }
};